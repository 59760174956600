<template>
    <BT-Blade-Items
        addBladeName="receival"
        archiveBladeName="receival-archives"
        bladeName="receivals"
        :bladesData="bladesData"
        canSearchLocal
        flexColumn
        :getParams="bladeData => { return { includeDetails: false, destinationLocationID: bladeData.data != null ? bladeData.data.locationID : null }}"
        :headers="[
            { text: 'Location', value: 'destinationLocation.locationName', subtitle: 1, textFilter: 'toFamiliarLocationLine' },
            { text: 'Due', value: 'dueArrivalOn', isIcon: true, textFilter: 'toDayMonth' },
            { text: 'Sender', value: 'seller.companyName', title: 1 },
            { text: 'Courier', value: 'courier.companyName' },
            { text: 'Status', value: 'status', display: true, subtitle: 2 }]"
        minimizeOnSelect
        navigation="receivals"

        title="Receivals"
        useServerPagination>
        <template v-slot:list="{ items }">
            <v-card v-for="(item, fIndex) in items" :key="fIndex" class="mx-1 mb-1" :to="{ name: 'receival', params: { id: item.id }}">
                <v-list-item three-line>
                    <v-list-item-avatar>
                        <v-img :src="companyLogoURL(item.sellerID)" class="my-auto">
                            <template v-slot:placeholder>
                                <v-icon class="primary--text" size="40">mdi-account-outline</v-icon>
                            </template>
                        </v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            <span v-if="item.deliveredOn != null">Delivered on {{ item.deliveredOn | toDayShortDate }}</span>
                            <span v-else-if="item.isPickup && item.releasedOn != null">Picked up on {{ item.releasedOn | toDayShortDate }}</span>
                            <span v-else>Due on {{ item.dueArrivalOn | toDayShortDate }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="!item.isPickup">
                            (via {{ item.courier.companyName }})
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <span v-if="item.receivedByUserID != null">Received</span>
                            <span v-if="item.deliveredOn != null || item.isPickup && item.releasedOn != null">Completed</span>
                            <span v-else-if="item.releasedOn != null">On Its Way</span>
                            <span v-else-if="item.isPickup">Requires Pickup</span>
                            <span v-else-if="item.journeyID != null">Journey Planned</span>
                            <span v-else>Pending</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <!-- <v-list-item-action>
                        
                            <span v-if="item.receivedByUserID != null">Received</span>
                            <span v-if="item.deliveredOn != null || item.isPickup && item.releasedOn != null">Completed</span>
                            <span v-else-if="item.releasedOn != null">On Its Way</span>
                            <span v-else-if="item.isPickup">Requires Pickup</span>
                            <span v-else-if="item.journeyID != null">Journey Planned</span>
                            <span v-else>Pending</span>
                        
                    </v-list-item-action> -->
                    <!-- <v-list-item-action>
                        <v-list-item-action-text>
                            <span v-if="item.itemType == 'invoice'">{{ item.amountTotal | toCurrency }}</span>
                            <span v-else>{{ item.amountTotal | toCurrency }}</span>
                        </v-list-item-action-text>
                        <span v-if="item.itemType == 'invoice' && item.paidOn == null" class="error--text">Unpaid</span>
                        <span v-else-if="item.itemType == 'invoice'" class="success--text">Paid</span>
                        <span v-else-if="item.itemType == 'delivery'">Delivery Scheduled</span>
                        <span v-else-if="item.isInactive" class="error--text">Deleted</span>
                        <span v-else-if="item.isDispatched == true" class="warning--text">Processed</span>
                        <span v-else-if="item.isConfirmed == null" class="warning--text">Awaiting Response</span>
                        <span v-else-if="item.isConfirmed == true" class="warning--text">Confirmed</span>
                        <span v-else class="error--text">Rejected</span>
                    </v-list-item-action> -->
                </v-list-item>
            </v-card>
        </template>
        <template v-slot:listItem="{ item }">
            <v-list-item-avatar>
                <v-img :src="companyLogoURL(item.sellerID)" class="mx-auto">
                    <template v-slot:placeholder>
                        <v-icon color="primary">mdi-account</v-icon>
                    </template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <span v-if="item.deliveredOn != null">Delivered On: {{ item.deliveredOn | toDayShortDate }}</span>
                    <span v-else-if="item.isPickup && item.releasedOn != null">Picked Up On: {{ item.releasedOn | toDayShortDate }}</span>
                    <span v-else>Due: {{ item.dueArrivalOn | toDayShortDate }}</span>
                </v-list-item-title>
                <v-list-item-subtitle v-if="!item.isPickup">
                    (via {{ item.courier.companyName }})
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-list-item-action-text>
                    <!-- <span>Status: </span> -->
                    <span v-if="item.receivedByUserID != null">Received</span>
                    <span v-if="item.deliveredOn != null || item.isPickup && item.releasedOn != null">Completed</span>
                    <span v-else-if="item.releasedOn != null">On Its Way</span>
                    <span v-else-if="item.isPickup">Requires Pickup</span>
                    <span v-else-if="item.journeyID != null">Journey Planned</span>
                    <span v-else>Pending</span>
                </v-list-item-action-text>
            </v-list-item-action>
        </template>
        <template v-slot:status="{ item }">
            <span v-if="item.receivedByUserID != null">Received</span>
            <span v-else-if="item.deliveredOn != null">Delivered</span>
            <span v-else-if="item.releasedOn != null">On Its Way</span>
            <span v-else>Pending</span>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Receivals-Blade',
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    }
}
</script>