var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"receival","archiveBladeName":"receival-archives","bladeName":"receivals","bladesData":_vm.bladesData,"canSearchLocal":"","flexColumn":"","getParams":function (bladeData) { return { includeDetails: false, destinationLocationID: bladeData.data != null ? bladeData.data.locationID : null }},"headers":[
        { text: 'Location', value: 'destinationLocation.locationName', subtitle: 1, textFilter: 'toFamiliarLocationLine' },
        { text: 'Due', value: 'dueArrivalOn', isIcon: true, textFilter: 'toDayMonth' },
        { text: 'Sender', value: 'seller.companyName', title: 1 },
        { text: 'Courier', value: 'courier.companyName' },
        { text: 'Status', value: 'status', display: true, subtitle: 2 }],"minimizeOnSelect":"","navigation":"receivals","title":"Receivals","useServerPagination":""},scopedSlots:_vm._u([{key:"list",fn:function(ref){
        var items = ref.items;
return _vm._l((items),function(item,fIndex){return _c('v-card',{key:fIndex,staticClass:"mx-1 mb-1",attrs:{"to":{ name: 'receival', params: { id: item.id }}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-avatar',[_c('v-img',{staticClass:"my-auto",attrs:{"src":_vm.companyLogoURL(item.sellerID)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-icon',{staticClass:"primary--text",attrs:{"size":"40"}},[_vm._v("mdi-account-outline")])]},proxy:true}],null,true)})],1),_c('v-list-item-content',[_c('v-list-item-title',[(item.deliveredOn != null)?_c('span',[_vm._v("Delivered on "+_vm._s(_vm._f("toDayShortDate")(item.deliveredOn)))]):(item.isPickup && item.releasedOn != null)?_c('span',[_vm._v("Picked up on "+_vm._s(_vm._f("toDayShortDate")(item.releasedOn)))]):_c('span',[_vm._v("Due on "+_vm._s(_vm._f("toDayShortDate")(item.dueArrivalOn)))])]),(!item.isPickup)?_c('v-list-item-subtitle',[_vm._v(" (via "+_vm._s(item.courier.companyName)+") ")]):_vm._e(),_c('v-list-item-subtitle',[(item.receivedByUserID != null)?_c('span',[_vm._v("Received")]):_vm._e(),(item.deliveredOn != null || item.isPickup && item.releasedOn != null)?_c('span',[_vm._v("Completed")]):(item.releasedOn != null)?_c('span',[_vm._v("On Its Way")]):(item.isPickup)?_c('span',[_vm._v("Requires Pickup")]):(item.journeyID != null)?_c('span',[_vm._v("Journey Planned")]):_c('span',[_vm._v("Pending")])])],1)],1)],1)})}},{key:"listItem",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-img',{staticClass:"mx-auto",attrs:{"src":_vm.companyLogoURL(item.sellerID)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account")])]},proxy:true}],null,true)})],1),_c('v-list-item-content',[_c('v-list-item-title',[(item.deliveredOn != null)?_c('span',[_vm._v("Delivered On: "+_vm._s(_vm._f("toDayShortDate")(item.deliveredOn)))]):(item.isPickup && item.releasedOn != null)?_c('span',[_vm._v("Picked Up On: "+_vm._s(_vm._f("toDayShortDate")(item.releasedOn)))]):_c('span',[_vm._v("Due: "+_vm._s(_vm._f("toDayShortDate")(item.dueArrivalOn)))])]),(!item.isPickup)?_c('v-list-item-subtitle',[_vm._v(" (via "+_vm._s(item.courier.companyName)+") ")]):_vm._e()],1),_c('v-list-item-action',[_c('v-list-item-action-text',[(item.receivedByUserID != null)?_c('span',[_vm._v("Received")]):_vm._e(),(item.deliveredOn != null || item.isPickup && item.releasedOn != null)?_c('span',[_vm._v("Completed")]):(item.releasedOn != null)?_c('span',[_vm._v("On Its Way")]):(item.isPickup)?_c('span',[_vm._v("Requires Pickup")]):(item.journeyID != null)?_c('span',[_vm._v("Journey Planned")]):_c('span',[_vm._v("Pending")])])],1)]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [(item.receivedByUserID != null)?_c('span',[_vm._v("Received")]):(item.deliveredOn != null)?_c('span',[_vm._v("Delivered")]):(item.releasedOn != null)?_c('span',[_vm._v("On Its Way")]):_c('span',[_vm._v("Pending")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }